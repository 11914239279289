import { useState, useContext } from 'react'
import { Input, Box, InputLabel, CircularProgress, Typography, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import '../sections/HeroSection.css';
import { CARDANO_EXPLORER_TESTNET, SCRIPT_ADDRESS_TESTNET } from '../config/constants'
import { WalletAPIContext } from '../context/WalletAPI/WalletAPIContext'
import { buildSendADATransaction } from '../utils/transactions/transactionsUtils'
import { TxSendError, TxSignError } from '../types/models'
import TxErrorDialog from './TxErrorDialog'
import dealmein from '../images/golddeal.png'

const SendForm = () => {
  const [amountInADA, setAmountInADA] = useState('')
  const [loading, setLoading] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorDialog, setErrorDialog] = useState<{
    open: boolean
    txError?: TxSendError | TxSignError
  }>({ open: false, txError: undefined })

  const { walletAPI, address } = useContext(WalletAPIContext)
  const handeErrorDialogClose = () => {
    setErrorDialog({ open: false, txError: undefined })
  }

  const handleSend = async () => {
    setLoading(true)
    setTxHash('')
    //console.log(`Sending ${amountInADA} $ADA...`)
    const tierMultiplyer = 20 //UPDATE THIS FOR COST OF TIER
    const lovelace = (parseInt(amountInADA) * 1000000) * tierMultiplyer
    const txResponse = await buildSendADATransaction(
      walletAPI!,
      address,
      SCRIPT_ADDRESS_TESTNET,
      lovelace.toString()
    )
    if (txResponse.hasOwnProperty('code')) {
      const _txError = txResponse as TxSendError | TxSignError
      setErrorDialog({ open: true, txError: _txError })
    } else {
      setTxHash(txResponse as string)
    }
    setAmountInADA('')
    setLoading(false)
  }

  const min = 1;
  const max = 40;
  //const [amountInADA, setValue] = useState<number>();
  const modalStylesend = {
    position: 'center',
    font: "ChampagneLimousines",
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    
  }
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: '#323E54',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    
  }

  return (
   <> 
   <div className="deal-popup primary-text" >
    <table>
      <tr>
        <td className="reminderaddr text-center" id="walletaddr">
          <span className="dmi-cto-large text-center" id="deal" >
            
            
            
          
        
          </span>
        </td>
      </tr>
      <tr>
        <td className="text-center">
          <ul className="dmi-cto-large">
            <li className="reminderred">
                      Enter the number of hands to deal
            </li>
            </ul>
                </td>
            </tr>
        </table>
        </div>
   <Box  className="deal-popup dmi-cto-large text-center">
      <InputLabel ></InputLabel>
      <Input 
        value={amountInADA}
        type="number"
        sx={modalStylesend}
        inputProps={{ min, max }}
        onChange={(e) => {
          

          if (e.target.value > max) e.target.value = max;
          if (e.target.value < min) e.target.value = min;

          setAmountInADA(e.target.value);
        }}




          
         
          
        
        style={{ color: 'rgb(200, 5, 30)', width: '120px', height: '12px', margin: '20px' }}
      /><br></br>
      <LoadingButton 
        
        loading={loading}
        onClick={() => handleSend()}
        loadingIndicator={<CircularProgress color="warning" size={55} />}
        style={{ fontSize: '32px' }}
      ><img src={dealmein} alt="Deal me in Button"></img>
        
      </LoadingButton>
      {txHash && (
        <Box>
          <Typography variant="body1">
            Transaction Submitted! 
          </Typography>
          <Typography variant="body2">
          Best of luck on drawing a great hand! You can view your NFT within your Flint, Nami, Eternl wallet! (It may take up to 30 minutes to receive your NFT, depending on network congestion.)
          </Typography>
        </Box>
      )}
      
      <TxErrorDialog
        open={errorDialog.open}
        text={errorDialog.txError?.info ?? ''}
        handleClose={handeErrorDialogClose}
      />
    </Box>
    <div className="deal-popup" >
    <table>
      <tr>
        <td className="reminderaddr text-center" id="walletaddr2">
          <span className="dmi-cto-large text-center" id="deal2" >
            
            
            
          
        
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <ul>
            <li className="reminderred">
              Maxiumum 40 Hands per transaction, unlimited number of transactions (Hands
              will be delivered to the wallet address that made the purchase)
            </li>
          
          <li className="reminder">
            If you want to purchase multiple hands and would like to save on
            transaction fees you can purchase up to 40 hands in a single transaction
          </li>
    
    <li className="reminderred">
      The DApp currently supports Eternl, Nami, and Flint Cardano Wallets.
    </li>
  
                    </ul>
                </td>
            </tr>
        </table>
        </div></>
  )
}

export default SendForm
