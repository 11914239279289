import cardTop from '../images/card-top.svg'
import './HeroSection.css'
import PopoutModal from '../modals/PopoutModal'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { isMobile } from 'react-device-detect'

const queryClient = new QueryClient()

export default function Hero() {
  return (
    <QueryClientProvider client={queryClient}>
      <Content />
    </QueryClientProvider>
  )
}

function getCounts(file: string) {
  const { isLoading, error, data } = useQuery(file, () =>
    fetch('https://cardanoroyalehome.blob.core.windows.net/$web/counts/' + file).then(res =>
      res.text()),
    {
      cacheTime: 1000 * 40, // 40 seconds
      refetchInterval: 1000 * 40, // 40 seconds
    }
  )
  
  if (isLoading) {
      return "Loading...."
  }

  if (error) {
    return "Failed to fetch count"
  }

  return data
}

function Content() {
  const hcount = getCounts('hands.txt')
  const rfcount = getCounts('rf.txt')
  const sfcount = getCounts('sf.txt')
  const kindcount = getCounts('kind.txt')
  const handscount = parseInt(hcount)
let showbutton;
  if (isMobile) {
     showbutton = <PopoutModal />
  } else { 
     showbutton = ""
   }

  return (
  <section className="hero-section">
    <div>
      {showbutton}
    </div><script src="./js/scrollFX.js?5181"></script>
    <script src="./utils/utils.tsx" defer></script>
    <div className="container">
      <div className="hand-stats ">
        <img src={cardTop} alt="Chevron Line Art" />
        <ul>
          <li className="stats-headers primary-text">Hands Dealt:</li>
          <li id="hands-count" className="stats-count dmi-cto-large2">
          {hcount}
          </li>
          <li className="stats-headers primary-text">Royal Flushes Remaining:</li>
          <li id="rf-count" className="stats-count dmi-cto-large2">
          {rfcount}
          </li>
          <li className="stats-headers primary-text">Straight Flushes Remaining:</li>
          <li id="sf-count" className="stats-count dmi-cto-large2">
           {sfcount}
          </li>
          <li className="stats-headers primary-text">Four of a Kind Remaining:</li>
          <li id="kind-count" className="stats-count dmi-cto-large2">
          {kindcount}
          </li>
        </ul>
      </div>
      <div className="hands-tier scroll-fx-left-in scroll-fx-left-out-fade">
        <img src={cardTop} alt="Chevron Line Art" />
        <div className="price dmi-cto-large2">
          <h1>Price</h1>
          <h2 id="t1-price" className={(handscount < 300000 ? 'current' : '')}>
            20 ADA
          </h2>
          <p id="t1-price-subtext" className={"subtext " + (handscount < 300000 ? 'current' : '')}>
            Current
          </p>
          <h2 id="t2-price" className={(299999 < handscount && handscount < 600000 ? 'current' : '')} >30 ADA</h2>
          <p id="t2-price-subtext" className={"subtext " + (299999 < handscount && handscount < 600000 ? 'current' : '')}>
            Current
          </p>
          <h2 id="t3-price" className={(599999 < handscount && handscount < 900000 ? 'current' : '')}>40 ADA</h2>
          <p id="t3-price-subtext" className={"subtext " + (599999 < handscount && handscount < 900000 ? 'current' : '')}>
            Current
          </p>
          <h2 id="t4-price" className={(899999 < handscount ? 'current' : '')}>50 ADA</h2>
          <p id="t4-price-subtext" className={"subtext " + (899999 < handscount ? 'current' : '')}>
            Current
          </p>
        </div>
        <div className="tier dmi-cto-large2">
          <h1>Tier</h1>
          <h2 id="t1" className={(handscount < 300000 ? 'current' : '')}>
            1
          </h2>
          <p id="t1-subtext" className={"subtext " + (handscount < 300000 ? 'current' : '')}>
            See Below
          </p>
          <h2 id="t2" className={(299999 < handscount && handscount < 600000 ? 'current' : '')}>2</h2>
          <p id="t2-subtext" className={"subtext " + (299999 < handscount && handscount < 600000 ? 'current' : '')}>
            See Below
          </p>
          <h2 id="t3" className={(599999 < handscount && handscount < 900000 ? 'current' : '')}>3</h2>
          <p id="t3-subtext" className={"subtext " + (599999 < handscount && handscount < 900000 ? 'current' : '')}>
            See Below
          </p>
          <h2 id="t4" className={(899999 < handscount ? 'current' : '')}>4</h2>
          <p id="t4-subtext" className={"subtext " + (899999 < handscount ? 'current' : '')}>
            See Below
          </p>
        </div>
      </div>
    </div>
  </section>
)
}