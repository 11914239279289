import './HeroSection.css';


const Quote = () => (
  <section className="quote-section">
    <div className="container-fluid quote-text">
      <blockquote className="pb-0">
        "Since this art surpasses all human subtlety and the perspicuity of
        mortal talent and is truly a celestial gift and a very clear test of the
        capacity of man's minds, whoever applies himself to it will believe that
        there is nothing that he cannot understand"
      </blockquote>
      <cite className="primary-text">
        <span className="primary-text" >Gerolamo Cardano</span> - Writer of the first systematic treatment
        of probability
      </cite>
    </div>
  </section>
);

export default Quote;
