import '../sections/HeroSection.css';
import { Button } from '@mui/material'
import SendForm from './SendForm'


  
const walletaddr = "addr1q8fwtxy0ks4ewquj4uje6ar9q3zhygkv3m6e7ewn0d8484yh6fw34j5jan65393w9fr8ys0ycfxu5j7dauhw6x48xcpsumrkzs"


const Popout = () => (

<div className="deal-popup about-section" >
    <table>
      <tr>
        <td className="reminderaddr text-center" id="walletaddr">
          <span className="dmi-cto-large about-section text-center" id="deal" >
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <ul>
            <li className="reminderaddr">
              
           {walletaddr}
            </li>
            <li className="text-center primary-text">
            <Button className="dmi-cto-large text-center primary-text" id="deal" type="button" 
        onClick={() => {navigator.clipboard.writeText(walletaddr)}}
      > <span className="primary-text reminderaddr">Copy To Clipboard</span>
             
      </Button>
            </li>
            <li className="reminderyellow reminderaddr">
              DApp available on Desktop!
            </li>
            <li className="reminderred reminderaddr">
              Maxiumum 40 Hands per transaction, unlimited number of transactions (Hands
              will be delivered to the wallet address that made the purchase)
            </li>
          
          <li className="reminder reminderaddr">
            If you want to purchase multiple hands and would like to save on
            transaction fees you can purchase up to 40 hands in a single transaction
          </li>
    
    <li className="reminderred reminderaddr">
      DO NOT SEND ADA to this wallet address directly from an exchange like
      Coinbase. You MUST transfer the funds from an exchange to your own personal wallet first
      (Eternl, Nami, Flint, Yoroi or Daedalus). NFTs delivered to an exchange wallet will be lost.
    </li>
  
                    </ul>
                </td>
            </tr>
        </table>
        </div>
);

export default Popout