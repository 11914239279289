import './HeroSection.css';
import discordLogoWhite from "../images/discordLogoWhite.png"
import instagramLogoSmall from "../images/instagramLogoSmall.png"
import twitterLogoWhite from "../images/twitterLogoWhite.png"
import socialHeader from "../images/socialHeader.svg"


const Social = () => (
  <section className="social-section">
    <div className="social-header container-fluid text-center">
      <img
        className="img-fluid"
        src={socialHeader}
        alt="Social Section Header Image"
      />
    </div>
    <div className="container">
      <div className="social-blade">
        <a href="https://twitter.com/CardanoRoyale">
          <img
            src={twitterLogoWhite}
            alt="@CardanoRoyale Twitter"
            title="Follow @CardanoRoyale on Twitter"
          />
        </a>
        <a href="https://www.instagram.com/cardano_royale/">
          <img
            src={instagramLogoSmall}
            alt="@cardano_royale Instagram"
            title="Follow @cardano_royale on Instagram"
          />
        </a>
        <a href="https://discord.gg/FP9bbewbT4">
          <img
            src={discordLogoWhite}
            alt="Cardano Royale Discord"
            title="Join the Cardano Royale Discord"
          />
        </a>
      </div>
    </div>
  </section>
);

export default Social;
