import './HeroSection.css';
import policyHeader from "../images/policyHeader.svg"
import BlobView from '../modals/Blobview'


const Policy = () => (
  <section className="policy-section scroll-fx-up-out-fade scroll-fx-up-in">
    <div className="policy-header container-fluid text-center">
      <img
        className="img-fluid"
        src={policyHeader}
        alt="Policy Section Header Image"
      />
    </div>
    <div className="container">
      <p>7968f276ec0b9b70831b79732e3f260e22691e3f2dc0ae363edc757e</p>
      <p>82499ae0105746faef1b0cf5c94860fdf1ace3b4b7bb75b642ab031e</p>
      <p>d55224806767e38b7ca0b988104e38a6d31f190afc2fb6d5e700465c</p>
    </div>
  </section>
);

export default Policy;
