import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, useMatch} from 'react-router-dom';
import logo from './logo.svg';
import './Main.css';
import MenuSection from "./MenuSection";
import Footer from "./FooterSection";
import Home from "../pages/Home";
import Faq from "../pages/Faq";
import Roadmap from "../pages/Roadmap";
import { QueryClientProvider, QueryClient } from 'react-query'


const queryClient = new QueryClient()


function App() {


  return (
  <QueryClientProvider client={queryClient}>
    <Router>
      <div className="MyMenuBar">
        <MenuSection />
      </div>

    <Routes>
      <Route path="/faq" element={<Faq />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/" element={<Home />} />
    </Routes>

    <div className="MyFooter">
      <Footer />
    </div>
    </Router>
  </QueryClientProvider>
  );
}

export default App;
