import './HeroSection.css';
import cardtop from "../images/card-top.svg" 

const height = "180px"
const width = "100%"

const HowTo = () => (
  <section className="how-to-section scroll-fx-up-out-fade scroll-fx-up-in">
    <div className="container-sm text-center">
      <div className="row">
        <div className="col-lg-6 col-sm-12 p-4">
          <h1 className="primary-text">How To Buy!</h1>
          <img src={cardtop} alt="Chevron Line Art" />
            <iframe className="videoframe" src="https://www.youtube.com/embed/SH0hAjZ8IRQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen height={height} width={width}></iframe>
        </div>
        <div className="col-lg-6 col-sm-12 p-4">
          <h1 className="primary-text">How To Sell!</h1>
          <img src={cardtop} alt="Chevron Line Art" />
          <iframe className="videoframe" src="https://www.youtube.com/embed/BxlQLmQBIIQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen height={height} width={width}></iframe>
        </div>
      </div>
    </div>
  </section>
);

export default HowTo;
