import Hero from '../sections/HeroSection';
import Sample from '../sections/SampleSection';
import About from '../sections/AboutSection';
import Policy from '../sections/PolicySection';
import HowTo from '../sections/HowToSection';
import Tiers from '../sections/TiersSection';
import Social from '../sections/SocialSection';
import Quote from '../sections/QuoteSection';

const Home = () => (
  <div>
    <Hero />
    <Sample />
    <About />
    <Policy />
    <HowTo />
    <Tiers />
    <Social />
    <Quote />
  </div>
);

export default Home;