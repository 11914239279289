import React from 'react';
import './index.css';
import App from './main/Main';
import { WalletAPIProvider } from './context/WalletAPI/WalletAPIProvider'
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client'



const rootElement = document.getElementById('root')

if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    
      <WalletAPIProvider >
        
          <App />
        
      </WalletAPIProvider>

  )
}