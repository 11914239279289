import { useContext, useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import { WalletAPIContext } from '../context/WalletAPI/WalletAPIContext'
import '../sections/HeroSection.css';
import SuccessModal from  './successModal'
import walletConnect from '../images/walletConnect.svg'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: '#323E54',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const modalStyle2 = {
  align: 'center',
  color: 'red',
  bgcolor: 'background',
}

const WalletSelectionModal = () => {
  const { cardano } = window
  const { selectWallet, selectedWallet, walletAPI } = useContext(WalletAPIContext)
  const [walletSelectionModal, setWalletSelectionModal] = useState(false)
  

  if (selectedWallet && walletAPI) return (
  <div className="collapse navbar-collapse px-5 nav-link active text-warning" id="navbarCollapse">
    <ul className="navbar-nav me-auto mb-2 mb-md-0">
      <li className="nav-item">
        <a className="nav-link active text-warning" aria-current="page">You have successfully connected your {selectedWallet} wallet! Click <SuccessModal /> to draw a hand!</a>
      </li>
    </ul>
  </div>  
  );
  else 

  return (
    <>
      <Button onClick={() => setWalletSelectionModal(true)}> 
        <img src={walletConnect}  alt="Connect Wallet Button" height="32"></img>
      </Button>

      <Modal
        open={walletSelectionModal}
        onClose={() => setWalletSelectionModal(false)}
      >
        <Box sx={modalStyle}>
          {!!(cardano?.nami || cardano?.flint || cardano?.eternl) && (
            <Typography variant="h6" component="h2" sx={modalStyle2}>
              Choose a Wallet
            </Typography>
          )}
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {cardano?.nami && (
              <Button
                variant="outlined"
                style={{ width: '160px', margin: '10px' }}
                onClick={() => selectWallet('Nami')}
              >
                <img
                  src={cardano.nami.icon}
                  alt=""
                  height="25px"
                  style={{ marginRight: '10px' }}
                />{' '}
                Nami
              </Button>
            )}
            {cardano?.flint && (
              <Button
                variant="outlined"
                style={{ width: '160px', margin: '10px' }}
                onClick={() => selectWallet('Flint')}
              >
                <img
                  src={cardano.flint.icon}
                  alt=""
                  height="25px"
                  style={{ marginRight: '10px' }}
                />{' '}
                Flint
              </Button>
            )}
            {cardano?.eternl && (
              <Button
                variant="outlined"
                style={{ width: '160px', margin: '10px' }}
                onClick={() => selectWallet('Eternl')}
              >
                <img
                  src={cardano.eternl.icon}
                  alt=""
                  height="25px"
                  style={{ marginRight: '10px' }}
                />{' '}
                Eternl
              </Button>
            )}
            {/* {cardano?.yoroi && (
              <Button
                variant="outlined"
                style={{ width: '160px', margin: '10px' }}
                onClick={() => selectWallet('Yoroi')}
              >
                <img
                  src={cardano.yoroi.icon}
                  alt=""
                  height="25px"
                  style={{ marginRight: '10px' }}
                />{' '}
                Yoroi
              </Button>
            )} */}
            {!(cardano?.nami || cardano?.flint || cardano?.yoroi || cardano?.eternl) && (
              <Typography variant="body1" color="orange">
                NO WALLETS FOUND: Please install one of the following: Nami,
                Flint or Eternl
              </Typography>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default WalletSelectionModal
