import './HeroSection.css';
import tiersHeader from "../images/tiersHeader.svg"

const Tiers = () => (
  <section className="tiers-section scroll-fx-up-out-fade scroll-fx-up-in">
    <div className="tiers-header container-fluid text-center">
      <img
        className="img-fluid"
        src={tiersHeader}
        alt="Tiers Section Header Image"
      />
    </div>
    <div className="container">
      <p>Tier 1 - Less than 300k hands dealt</p>
      <p>Tier 2 - Less than 600k hands dealt</p>
      <p>Tier 3 - Less than 900k hands dealt</p>
      <p>Tier 4 - All Remaining Hands</p>
    </div>
  </section>
);

export default Tiers;
