import roadmapHeader from "../images/roadmapHeader.svg" 
import roadmapLarge from "../images/roadmapLarge.png"
import roadmapMobile from "../images/roadmapMobile.png"
import '../sections/HeroSection.css'
import { isMobile } from 'react-device-detect'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'



export default function Roadmap() {
  return (
    
      <Content />
    
  )
}

function Content() {
let showmobileroadmap;
  if (isMobile) {
     showmobileroadmap = roadmapMobile
  } else { 
     showmobileroadmap = roadmapLarge
   }

return (
  <section className="roadmap-section">
    <div className="roadmap-header container-fluid text-center"><img className="img-fluid" src={roadmapHeader} alt="Roadmap Section Header Image"></img></div>
        <div className="row">
            <img src={showmobileroadmap}></img>
        </div>
  </section>
)
}