import { useContext, useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import buttonDivider from '../../../images/button-divider.svg';
import { WalletAPIContext } from '../context/WalletAPI/WalletAPIContext'
import Popout from './popout'
import '../sections/HeroSection.css';
import dealmein from '../images/golddeal.png'
import SendForm from './SendForm'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#323E54',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  
}

const SuccessModal = () => {
  
  const [popoutSelectionModal, setPopoutSelectionModal] = useState(false)

 

  return (
    <>
      <Button className="dmi-cto-large2 text-center  " id="deal" type="button" 
        onClick={() => setPopoutSelectionModal(true)}>
          <img src={dealmein} alt="Deal me in Button"></img>
      </Button>

      <Modal className="deal-popup primary-text" 
        open={popoutSelectionModal}
        onClose={() => setPopoutSelectionModal(false)}
      >
        <Box sx={modalStyle}>
          {
            <Typography variant="h6" component="h2">
             <SendForm />
            </Typography>
          }
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Button onClick={() => Popout}></Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default SuccessModal






