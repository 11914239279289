import logo from '../images/menuLogo.svg';
import header from '../images/pageHeader.png';
import headerLarge from '../images/pageHeaderLarge.png';
import WalletSelectionModal from '../modals/WalletSelectionModal';
import '../sections/HeroSection.css';
import { isMobile } from 'react-device-detect'
import { Nav, Navbar, Container, } from 'react-bootstrap';





const MenuSection = () => {
  let showbutton;
  if (isMobile) {
    showbutton = ""
  } else { 
    showbutton = <WalletSelectionModal />
   }
  return (
    <>
  <Navbar className="px-1" collapseOnSelect expand="md" variant="dark" fixed="top" color='#262f3f'>
  <Container fluid>
  <Navbar.Brand className="primary-text reminderyellow" href="/"><img className="px-4" src={logo} height="32"/>Cardano Royale</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto px-4">
      <Nav.Link className="primary-text"  href="/">Home</Nav.Link>
      <Nav.Link className="primary-text" href="/faq">FAQ</Nav.Link>
      <Nav.Link className="primary-text" href="/roadmap">Roadmap</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Item >{showbutton}</Nav.Item>
      
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>     
      
    
    <div className="container-fluid p-0 m-0 section-standard" id="pageBanner">
        <picture>
          <source srcSet={headerLarge} media="(min-width: 768px)" />
          <img className="img-fluid mt-5" src={header} alt="Header Image" />
        </picture>
    </div>
    </>
  )
}

export default MenuSection;