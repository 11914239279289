import aboutHeader from "../images/aboutHeader.svg"
import './HeroSection.css';

const About = () => (
  <section className="section-standard about-section scroll-fx-up-out-fade scroll-fx-up-in">
    <div className="about-header container-fluid text-center">
      <img className="img-fluid" src={aboutHeader} alt="About Section Header Image" />
    </div>
    <div className="primary-text container pt-3 .hero-section .dmi-cto-large2">
      <p>Inspired by the architecture of a beloved noir era, well-tailored heroes, brutal villains, enchanting
      women, and a game of chance, skill, and deceit - Our project aspired to create the premier NFT
      ownership experience. The Cardano Royale platform assures no one else in the world will own the
      exact hand we deal you.</p>
      <p>A traditional deck uses the Ace of spades as its signature card. In keeping with the symbolism of the
      blockchain and Art Deco themes of our cards, we have chosen the Diamond as our signature suit. Ergo,
      we consider the Diamond Royal Flush our most valuable hand. This hand contains character card
      alternates found only in the 3 Diamond Straight Flushes ending in J, Q or K.</p>
      <p>Cardano Royale NFTs are minted on the Cardano Blockchain and are purchased with Cardano's coin ADA.
      As part of our offering, we have removed all hands that are not at least a pair. See our FAQ for
      answers to common questions <a href="/faq">Cardano Royale FAQ</a></p>
    </div>
  </section>
);

export default About;