import faqHeader from "../images/faqHeader.svg"
import '../sections/HeroSection.css';


const Faq = () => (
  <section className="faq-section">
    <div className="faq-header container-fluid text-center">
      <img
        className="img-fluid"
        src={faqHeader}
        alt="FAQ Section Header Image"
      />
    </div>
    <div className="container primary-text">
      <h1>
        <span>Q: </span> Why did I receive ADA with my NFT?
      </h1>
      <p>
        <span>A: </span> Transactions on the Cardano Blockchain requires that
        each UTxO must contain an amount of ADA that depends on the size of the
        entry. For more information see{" "}
        <a className="linkcolor" href="https://cardano-ledger.readthedocs.io/en/latest/explanations/min-utxo-alonzo.html#example-min-ada-value-calculations-and-current-constants">
          Min-Ada-Value Calculation in Alonzo - (cardano-ledger.readthedocs.io)
        </a>
      </p>
      <h1>
        <span>Q: </span> Why did I only receive 40 NFTs, but sent enough for 50?
      </h1>
      <p>
        <span>A: </span> We are only able to mint and deliver 40 NFTs in a
        single transaction. This is to ensure that any single output is not so
        big that a transaction attempting to spend it would have to exceed the
        maximum transaction size. If a single output was too big you would be
        unable to send any of your NFTs to another wallet in the future
      </p>
      <h1>
        <span>Q: </span> Why did I not receive my NFT?
      </h1>
      <p>
        <span>A: </span> Valid Transactions are processed and delivered by an
        automated system. If a valid transaction is received but the automated
        system fails to Mint and Deliver the NFTs the failure is logged. These
        failures are reviewed and corrected by a human within 24 hours. If more
        than 24 Hours has passed please contact us here{" "}
        <a className="linkcolor" href="mailto:purchasesupport@cryptographic-designers.io" >
          {" "}
          purchasesupport@cryptographic-designers.io
        </a>{" "}
        with the transaction ID
      </p>
    </div>
  </section>
);

export default Faq;
