import CardanoRoyaleSample from "../images/CardanoRoyaleSample.jpg"
import './HeroSection.css';

const Sample = () => (
  <section className="sample-section">
    <div className="container text-center p-sm-5">
      <img
        className="img-fluid"
        src={CardanoRoyaleSample}
        alt="Sample NFT Image"
      />
    </div>
  </section>
);

export default Sample;
