import { useContext, useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import buttonDivider from '../images/button-divider.svg';
import { WalletAPIContext } from '../context/WalletAPI/WalletAPIContext'
import Popout from './popout'
import '../sections/HeroSection.css';
import SendForm from './SendForm'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '375px',
  bgcolor: '#323E54',
  boxShadow: 24,
  p: 4,
  border: '#e0bc5b solid 0.5px',
  
}

const PopoutModal = () => {
  const [popoutSelectionModal, setPopoutSelectionModal] = useState(false)
  return (
    <>
      <Button className="dmi-cto-large text-center  " id="deal" type="button" 
        onClick={() => setPopoutSelectionModal(true)}
      > Available Now <br></br>
      <img 
                  src={buttonDivider}
                  alt="btn button text divider img-fluid"
                  height="25px"
                /><br></br><span>Deal Me In!</span>        
      </Button>
      <Modal className="deal-popup primary-text" 
        open={popoutSelectionModal}
        onClose={() => setPopoutSelectionModal(false)}
      >
        <Box sx={modalStyle}>
          {
            <Typography variant="h6" component="h2">
             <Popout/>
            </Typography>
          }
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
              
          </Box>
          </Box>
          </Modal>
          </>
            
    
  );
}
//placing class 'about section'in Modal takes the background 
export default PopoutModal